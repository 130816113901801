const primaryR = 65;
const primaryG = 226;
const primaryB = 186;

// ts-unused-exports:disable-next-line
export const dark = {
    r: 20,
    g: 20,
    b: 20,
};
// ts-unused-exports:disable-next-line
export const primary = [primaryR, primaryG, primaryB];

export const successHex = "#10dc60";
export const warningHex = "#ffce00";
export const dangerHex = "#f04141";
export const primaryHex = "#34c29f";
// export const primaryHexLight = "#ccf0e7";
export const secondaryHex = "#d83148";
export const darkHex = "#141414";
export const lightHex = "#f7f7f7";
export const mediumHex = "#cccccc";
