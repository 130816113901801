import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import "./formControlLabel.scss";

type FormControlLabelProps = ComponentPropsWithoutRef<"label">;
export const FormControlLabel = (props: FormControlLabelProps) => {
    const { htmlFor: controlledHtmlFor, ...restProps } = props;
    const { id: uncontrolledHtmlFor } = useFormControlContext();

    const htmlFor = controlledHtmlFor || uncontrolledHtmlFor;

    return <label {...restProps} htmlFor={htmlFor} className={clsx("form-control-label", restProps.className)} />;
};
