import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { ProvideFormControl } from "@/components/FormControlContext/FormControlContext";
import "./formControlRoot.scss";

type FormControlRootOwnProps = {
    /**
     * @default "stacked"
     */
    layout?: "stacked" | "inline";
};
type FormControlRootProps = FormControlRootOwnProps &
    Omit<ComponentPropsWithoutRef<"fieldset">, keyof FormControlRootOwnProps>;

export const FormControlRoot = (props: FormControlRootProps) => {
    const { layout = "stacked", ...restProps } = props;

    return (
        <ProvideFormControl>
            <fieldset
                {...restProps}
                className={clsx(
                    "form-control-root",
                    {
                        "form-control-root--stacked": layout === "stacked",
                        "form-control-root--inline": layout === "inline",
                    },
                    restProps.className
                )}
            />
        </ProvideFormControl>
    );
};
